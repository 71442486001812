.nextui-modal-body {
  max-height: 300px;
  overflow: scroll;
}

@media (min-width: 800px) {
  .nextui-modal-body {
    max-width: 550px;
  }

  .nextui-backdrop-content {
    max-width: 550px;
  }
}

.country {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.concert {
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  margin-bottom: 5px;
}

.concert .nextui-button-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
