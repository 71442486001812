@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
  overflow: hidden;
}

.nextui-modal-body{
    max-height: 300px;
    overflow: scroll;
}

.country {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.concert {
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  margin-bottom: 5px;
}

.concert .nextui-button-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;
  padding-top: 120px;
  background-color: #0f1128;
  color: white;
  background-image: url(/static/media/theerastour-ts.5cf8c215.png);
  background-size: cover;
  padding-bottom: 100px;
}

.credits{
  width: 100%;
  background-color: #ba74ef;
  padding: 6px 15px;
  text-align: center;
  margin-bottom: 60px;
  cursor: pointer;
  position: absolute;
  top: 0;
}

.floating-tay {
  position: absolute;
  bottom: -50px;
  left: 0px;
  height: 60vh;
  width: 100%;
  object-fit: cover;
  -webkit-animation: float 6s ease-in-out infinite;
          animation: float 6s ease-in-out infinite;
  -webkit-transform: translatey(-10px);
          transform: translatey(-10px);
}

@-webkit-keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

.country-tag{
  padding: 5px 10px;
  background-color: white;
  border-radius: 8px;
  color: #0f1128;
  font-weight: bold;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
}

.country-flag{
  width: 15px;
  height: 15px;
  border-radius: 100px;
  object-fit: cover;
  margin-right: 7px;
}
.days-until{
  font-size: 160px;
  font-weight: bold;
  opacity: 0.9;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  font-family: 'Josefin Sans';
  transition: all .1s linear;
  line-height: 0.8;
  margin-top: 50px;
}

.confetti-tip{
  z-index: 100;
  position: absolute;
  top: 150px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  opacity: 0.8;
}

.the-eras-tour-title {
  width: 97vw;
  text-align: center;
  font-size: 40px;
  font-family: Georgia, "Times New Roman", Times, serif;
  text-shadow:0 0 32px
      #ba74ef,
    0 0 68px #ba74ef, 0 0 125px #ba74ef;
}

@font-face {
  font-family: 'Brittany';
  src: local('Brittany'), url(/static/media/BrittanySignature.0d8ad040.ttf) format("truetype");
}

.taylor-swift-caption {
  font-family: 'Brittany';
  font-size: 40px;
  color: #c4a8fb;
  margin-top: -10px;
  z-index: 100;
}

.share-button{
  position: absolute;
  bottom: 20vh;
  background-color:#ffffff26;
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 5px 13px;
  display: flex;
  align-items: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.share-icon{
  margin-right: 7px;
  height: 18px;
}

.country-flag {
    width: 20px;
    height: 20px;
    border-radius: 100px;
    object-fit: cover;
    margin-right: 7px;
  }
.nextui-modal-body {
  max-height: 300px;
  overflow: scroll;
}

@media (min-width: 800px) {
  .nextui-modal-body {
    max-width: 550px;
  }

  .nextui-backdrop-content {
    max-width: 550px;
  }
}

.country {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.concert {
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  margin-bottom: 5px;
}

.concert .nextui-button-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

