.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;
  padding-top: 120px;
  background-color: #0f1128;
  color: white;
  background-image: url("../assets/images/theerastour-ts.png");
  background-size: cover;
  padding-bottom: 100px;
}

.credits{
  width: 100%;
  background-color: #ba74ef;
  padding: 6px 15px;
  text-align: center;
  margin-bottom: 60px;
  cursor: pointer;
  position: absolute;
  top: 0;
}

.floating-tay {
  position: absolute;
  bottom: -50px;
  left: 0px;
  height: 60vh;
  width: 100%;
  object-fit: cover;
  animation: float 6s ease-in-out infinite;
  transform: translatey(-10px);
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

.country-tag{
  padding: 5px 10px;
  background-color: white;
  border-radius: 8px;
  color: #0f1128;
  font-weight: bold;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
}

.country-flag{
  width: 15px;
  height: 15px;
  border-radius: 100px;
  object-fit: cover;
  margin-right: 7px;
}

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;500&display=swap');
.days-until{
  font-size: 160px;
  font-weight: bold;
  opacity: 0.9;
  user-select: none;
  cursor: pointer;
  font-family: 'Josefin Sans';
  transition: all .1s linear;
  line-height: 0.8;
  margin-top: 50px;
}

.confetti-tip{
  z-index: 100;
  position: absolute;
  top: 150px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  opacity: 0.8;
}

.the-eras-tour-title {
  width: 97vw;
  text-align: center;
  font-size: 40px;
  font-family: Georgia, "Times New Roman", Times, serif;
  text-shadow:0 0 32px
      #ba74ef,
    0 0 68px #ba74ef, 0 0 125px #ba74ef;
}

@font-face {
  font-family: 'Brittany';
  src: local('Brittany'), url('../assets/fonts/BrittanySignature.ttf') format("truetype");
}

.taylor-swift-caption {
  font-family: 'Brittany';
  font-size: 40px;
  color: #c4a8fb;
  margin-top: -10px;
  z-index: 100;
}

.share-button{
  position: absolute;
  bottom: 20vh;
  background-color:#ffffff26;
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 5px 13px;
  display: flex;
  align-items: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.share-icon{
  margin-right: 7px;
  height: 18px;
}
